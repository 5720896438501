<template>
  <v-container>
    <v-row>
      <v-col class="px-6" cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="mt-5">Archivierte Checklisten</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <h3 class="my-4">Checklisten nach alter:</h3>

            <v-select
                :items="vehicles"
                v-model="filter"
                item-text="name"
                item-value="_id"
                label="Filter nach Fhz"
                class="my-4"
                clearable
                @change="getChecklists"
            ></v-select>

            <v-expansion-panels
                v-model="panel"
            >
              <v-expansion-panel
                  v-for="checklist in checklists"
                  :key="checklist._id"
              >
                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <v-col cols="9">
                      <span class="font-weight-bold">{{ checklist.identifier }}</span><br>
                      <span v-if="checklist.updatedAt">Datum: {{checklist.updatedAt | moment}}</span>
                    </v-col>
                    <v-col
                        cols="3"
                        class="text--secondary"
                    >
                      <v-row
                          no-gutters
                          style="width: 100%"
                      >
                        <v-col cols="6">

                        </v-col>
                        <v-col cols="6">
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row no-gutters>

                    <v-col cols="5" v-if="checklist.editor && checklist.updatedAt">
                      Author: <strong>{{checklist.editor}}</strong><br>
                      Zuletzt bearbeitet: <strong>{{checklist.editor}}</strong>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-divider
                        vertical
                        class="mx-4"
                    ></v-divider>
                    <v-col cols="3">

                    </v-col>
                  </v-row>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="openList(checklist)"
                    >
                      Anzeigen
                    </v-btn>
                  </v-card-actions>

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
        v-model="dialog.confirmArchive"
        persistent
        max-width="290"
    >
      <v-card v-if="selectedList">
        <v-card-title class="headline">
          Checkliste {{ selectedList.title }} archivieren?
        </v-card-title>
        <v-card-text>Diese Checkliste definiv archivieren?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              text
              @click="dialog.confirmArchive = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="archiveList()"
          >
            Archivieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snack.state"
        :timeout="snack.timeout"
        :color="snack.color"
        elevation="12"
        top
        right
    >
      {{ snack.message }}
    </v-snackbar>


  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Review",
  data() {
    return {
      vehicles: null,
      checklists: null,
      selectedList: null,
      panel: null,
      filter: null,
      dialog: {
        confirmArchive: false
      },
      snack: {
        state: false,
        color: 'success',
        timeout: 2000,
        message: 'Checkliste wurde erfolgreich gespeichert!',
      }
    }
  },
  created() {

    this.getVehicles()

  },
  mounted() {
    this.getChecklists()
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD.MM.YY HH:mm');
    }
  },
  methods: {
    getVehicles() {
      axios
          .get(process.env.VUE_APP_API_URL + '/vehicles')
          .then((response) => {
            this.vehicles = response.data
          })
    },
    getChecklists() {
      axios
          .get(process.env.VUE_APP_API_URL + '/pchecklists/state/archived/' + this.filter)
          .then((response) => {
            this.checklists = response.data
          })
    },
    openList(list) {
      this.$router.push({name: 'PopulateChecklist', params: {id: list._id, newList: 'false', readOnly: 'true'}})

    },
    confirmArchive(list) {
      this.selectedList = list
      this.dialog.confirmArchive = true
    },
    archiveList() {
      axios.patch(process.env.VUE_APP_API_URL + '/pchecklists/' + this.selectedList._id, {state: 'archived'})
          .then((response) => {
            console.log(response)
            if (response.status === 200) {
              this.dialog.confirmArchive = false
              this.getVehicles()
              this.snack.message = 'Checkliste wurde erfolgreich archviert.'
              this.snack.state = true
            }
          })
    }

  }
}
</script>

<style scoped>

</style>